<template>
  <div class="pl-order-detail-wrap">
    <div class="module-title" style="margin-bottom:30px;"> 订单详情</div>
    <div class="pl-order-info-box" style="color: #D9262c;">
      <p>订单号：{{ info.sn }}</p>
      <span>|</span>
      <p>状态：{{ statusOptions[info.status] }}</p>
      <span>|</span>
      <p>支付方式：{{ options[info.pay_type] }}</p>
      <span>|</span>
      <p>下单人：{{ info.user.name }}</p>
    </div>
    <div class="module-title margin">收货信息</div>
    <div class="pl-order-addr-box">
      <div class="pl-order-row pl-flex-start">
        <div class="pl-label">公司名称</div>
        <div class="pl-value">{{ info.company.company_name }}</div>
      </div>
      <div class="pl-order-row pl-flex-start">
        <div class="pl-label">收货地址</div>
        <div class="pl-value">{{ info.order_ship.address }}</div>
      </div>
      <div class="pl-order-row pl-flex-start">
        <div class="pl-label">收货人</div>
        <div class="pl-value">{{ info.order_ship.consignee }}</div>
      </div>
      <div class="pl-order-row pl-flex-start">
        <div class="pl-label">联系方式</div>
        <div class="pl-value">{{ info.order_ship.mobile }}</div>
      </div>
      <div class="pl-order-row pl-flex-start">
        <div class="pl-label">邮编</div>
        <div class="pl-value">{{ info.order_ship.post_code }}</div>
      </div>
    </div>
    <div class="module-title margin">商品明细</div>
    <el-table :data="info.order_goods" border class="pl-table">
      <el-table-column
          label="商品名称" width="200" prop="goods_name" header-align="center"
          align="center"></el-table-column>
      <el-table-column
          label="商品型号" width="200" prop="production_model" header-align="center"
          align="center"></el-table-column>
      <el-table-column label="数量" prop="quantity" width="160" header-align="center" align="center">
      </el-table-column>
      <el-table-column label="单价" prop="price" header-align="center" align="center"></el-table-column>
      <el-table-column label="单价(含税)" prop="tax_price" header-align="center" align="center"></el-table-column>
      <el-table-column label="发货天数" prop="ship_days" header-align="center" align="center"></el-table-column>
      <el-table-column label="合计" prop="total_price" header-align="center" align="center"></el-table-column>
    </el-table>
    <div class="pl-info-box">
      <div class="pl-info-item">
        <span>配送方式</span>
        <span>{{ info.order_ship.delivery_method == 1 ? '一起发货' : '分开发货' }}</span>
      </div>
      <div class="pl-info-item" v-if="info.created_at">
        <span>创建时间</span>
        <span>{{ info.created_at }}</span>
      </div>
      <div class="pl-info-item" v-if="info.cancel_at">
        <span>取消时间</span>
        <span>{{ info.cancel_at }}</span>
      </div>
      <div class="pl-info-item" v-if="info.cancel_desc">
        <span>取消原因</span>
        <span>{{ info.cancel_desc }}</span>
      </div>
      <div class="pl-info-item" v-if="info.paid_at">
        <span>付款时间</span>
        <span>{{ info.paid_at }}</span>
      </div>
    </div>
    <div class="pl-btn-box">
      <el-button type="primary" plain v-if="info.status == 1" @click="goPay(info)"> 去支付</el-button>
      <el-button type="primary" plain v-if="[2, 3, '2', '3'].indexOf(info.status) > -1" @click="applySales(info)">
        申请售后
      </el-button>
      <el-button type="primary" plain v-if="info.status == 1" @click="cancelOrder(info)"> 取消订单</el-button>
      <el-button type="primary" plain v-if="info.status == 3" @click="confirmOrder(info)"> 确认收货</el-button>
      <el-button type="primary" plain @click="dialogVisible1 = true"> 查看物流</el-button>
      <el-button type="primary" plain @click="back">返回</el-button>
    </div>
    <!-- 查看物流 -->
    <el-dialog :visible.sync="dialogVisible1" title="物流信息">
      <div v-if="info.order_ship.logistics_info.length" class="pl-dialog-row">

        <div v-for="(item, index) in info.order_ship.logistics_info" style="white-space: pre-line;" :key="index">
          <el-descriptions :colon="false" :title="`第${index + 1}批货`">
            <el-descriptions-item>{{ item.express_name }}</el-descriptions-item>
            <el-descriptions-item>{{ item.express_num }}</el-descriptions-item>
            <el-descriptions-item>
              <el-button type="text" @click="viewLogisticsDetails(item)">查看</el-button>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div v-else>
        暂无信息
      </div>
      <!-- <div class="pl-dialog-row">
          <p>第2批货</p>
          <p>顺丰快递: UYJ87987987</p>
      </div> -->
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleLogisticsDetails" title="物流详情">
      <el-timeline>
        <el-timeline-item
            v-for="(item, index) in logisticsDetailsList"
            :key="index"
            :color="index === 0 ? '#D9262C': ''"
            :timestamp="item.time">
					<span :style="{color: index === 0 ? '#D9262C': ''}">
						{{ item.status }}
					</span>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <!-- 取消订单 -->
    <el-dialog title="请选择取消订单的原因" :visible.sync="dialogVisible" width="30%">
      <div class="pl-radio-box">
        <el-radio v-model="cancel_desc" label="拍错数量">拍错数量</el-radio>
        <br>
        <el-radio v-model="cancel_desc" label="不想要了">不想要了</el-radio>
        <br>
        <el-radio v-model="cancel_desc" label="其他">其他</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmCancelOrder">确 定</el-button>
            </span>
    </el-dialog>
    <PayQr :id="wxId" :wxurl="wxUrl" :show.sync="showWxPay"/>
  </div>
</template>
<script>
import {cancelOrder, confirmReceipt, logisticsInfo, orderDetail} from '@/api/order.js'
import {alipay, wxpay} from '@/api/pay.js'
import PayQr from '@/views/user/shoppingCart/pay.vue'

export default {
  components: {
    PayQr
  },
  data() {
    return {
      dialogVisible1: false,//物流
      dialogVisible: false,//取消订单弹窗
      alipay,
      tableData: [],
      id: this.$route.query.id,
      info: {order_ship: {}},
      cancel_desc: '',
      options: ['全部', '微信', '支付宝', '银联'],
      statusOptions: ['已取消', '待支付', '待发货', '待收货', '售后中', '售后完成', '已完成'],
      wxId: '',
      wxUrl: '',
      showWxPay: false,
      dialogVisibleLogisticsDetails: false,
      logisticsDetailsList: [],
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    viewLogisticsDetails(item) {
      let data = {
        order_id: this.info.id,
        express_num: item.express_num,
        // express_num: 'JD0103390299807'
        express_code: item.express_code
      }
      logisticsInfo(data).then(res => {
        console.log(res);
        this.dialogVisibleLogisticsDetails = true
        this.logisticsDetailsList = res || []
      })
    },
    applySales(data) {
      this.$router.push(`/user/order/sales?id=${data.id}`)
      console.log(data)

    },
    back() {
      this.$router.go(-1)
    },
    getDetail() {
      if (!this.id) return false
      orderDetail(this.id).then(res => {
        this.info = res || {}
      })
    },
    // 确认收货
    confirmOrder(data) {
      confirmReceipt(data.id).then(() => {
        this.$message.success('确认收货成功')
        this.getDetail()

      })
      console.log(data)
    },
    // 取消订单
    cancelOrder(data) {
      this.dialogVisible = true
      console.log(data)
      this.cancelId = data.id

    },
    confirmCancelOrder() {
      cancelOrder(this.info.id, {cancel_desc: this.cancel_desc}).then(res => {
        this.dialogVisible = false
        console.log(res)
        this.$message.success('取消成功')
        this.getDetail()
      })
    },
    // 去支付
    goPay(data) {
      switch (+data.pay_type) {
        case 1:
          wxpay({order_id: data.id}).then(({code_url}) => {
            this.wxId = data.id
            this.wxUrl = code_url
            this.showWxPay = true
          })
          break
        case 2:
          this.alipay += data.id
          window.open(this.alipay, '_blank')
          break
        case 3:
          this.$router.push('/user/shopping/bank?id=' + data.id)
          break
      }
      console.log(data)

    },

  }
}
</script>
<style lang="scss" scoped>
.pl-order-detail-wrap {
  .margin {
    margin: 50px 0 30px;
  }

  .pl-btn-box {
    margin-top: 30px;

    ::v-deep.el-button--primary {
      background: #f3e9e9;
    }
  }

  .pl-order-info-box {
    width: 100%;
    height: 50px;
    background: #f3e9e9;
    color: #D9262c;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .pl-order-addr-box {
    .pl-order-row {
      background: #fff;
      color: #737373;
      width: 100%;
      height: 40px;
      margin-top: 2px;

      .pl-label {
        width: 150px;
        text-align: center;
        height: 100%;
        line-height: 40px;
        background-color: #ececec;
        color: #000000;
      }

      .pl-value {
        margin-left: 15px;
      }
    }
  }

  .pl-info-box {
    margin-top: 30px;

    .pl-info-item {
      margin-bottom: 15px;
      color: #000;

      span:nth-of-type(1) {
        color: #ADADAE;
        padding-right: 15px;
      }
    }
  }

  .pl-dialog-row {
    margin-bottom: 15px;

    p {
      margin-bottom: 10px;
    }
  }

  .pl-radio-box {
    ::v-deep.el-radio {
      margin: 15px 0
    }
  }
}

::v-deep {
  .el-table tr {
    background-color: #ffffff;
  }

  .el-button--primary.is-plain {
    border-color: #D9262c;
    color: #D9262c;
    background-color: #f5f5f5;
  }

  .el-button--primary.is-plain:hover {
    background-color: #D9262c;
    color: #fff;
  }
}
</style>
